
<div class="container">
    <div class="row">
<div class="col-12 mb-2 NoData_Div pt-100 pb-70 mt-lg-3 mt-5" *ngIf="success" >
    <div class="card">
        <div class="card-body">
            <div class="img">
                <img
                    src="../../../../assets/img/checked.png"
                />
            </div>
            <div class="txt mt-3 mb-4">
                <p class="mb-0">
                   {{translate.instant('SuccessfulPaymentProcess')}}
                </p>
            </div>
        </div>
    </div>
</div>
<div class="col-12 mb-2 NoData_Div pt-100 pb-70 mt-lg-3 mt-5"  *ngIf="!success" >
    <div class="card">
        <div class="card-body">
            <div class="img">
                <img
                    src="../../../../assets/img/declined.png"
                />
            </div>
            <div class="txt mt-3 mb-4">
                <p class="mb-0">
                   {{translate.instant('DeclinedPaymentProcess')}}
                </p>
            </div>
        </div>
    </div>
</div>
</div>
</div>

