import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';

@Component({
    selector: 'app-article-details',
    templateUrl: './article-details.component.html',
    styleUrls: ['./article-details.component.scss'],
})
export class ArticleDetailsComponent implements OnInit {
    Topic!: any;
    constructor(
        public translate: TranslateService,
        private articlesClient: WaClient,
        private RouteParam: ActivatedRoute
    ) {
        let routeparamsSub = this.RouteParam.params.subscribe((params) => {
            this.getArticleByID(params['id']);
        });
    }
    getTitle(Topic: any) {
        if (this.translate.currentLang == 'en') {
            return Topic?.Topics_Title_En;
        } else {
            return Topic?.Topics_Title_Ar;
        }
    }
    getDescription(Topic: any) {
        if (this.translate.currentLang == 'en') {
            return Topic?.Topics_Desc_En;
        } else {
            return Topic?.Topics_Desc_Ar;
        }
    }
    getArticleByID(id: any) {
        this.articlesClient
            .wa_GetTopics({ TopicId: id })
            .subscribe((response: any) => {
                this.Topic = response.Topics[0];
                console.log(response);
            });
    }
    ngOnInit(): void {}
}
