<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{translate.instant('OurServices')}}</h2>
                    <ul>
                        <li><a routerLink="/">{{translate.instant('MainPage')}}</a></li>
                        <li>{{translate.instant('Services')}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>{{translate.instant('OurServices')}}</span>
            <h2>Safe, Faster and Relaible Logistics Services</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Service of Services">
                <div class="service-card-two">
                    <img [src]="getDoctorImg(Service)" alt="image">
                    <div class="service-caption">
                          <div [innerHTML]="getServiceName(Service)"> </div>
                        <!-- <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p> -->
                    <a (click)="gotoServiceDetails(Service.ID)"
                    class="default-btn-two">{{translate.instant('ReadMore')}}</a>

                    </div>
                </div>
            </div>

           
        </div>
    </div>
</div>
