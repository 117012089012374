import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-service-details',
    templateUrl: './service-details.component.html',
    styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent implements OnInit {
    Service!: any;
    URLImg: any = 'https://profadmin.careofme.net/Uploads/Service/';

    constructor(
        public translate: TranslateService,
        private ServiceClient: WaClient,
        private RouteParam: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) {
        let routeparamsSub = this.RouteParam.params.subscribe((params) => {
            this.getServiceByID(params['id']);
        });
    }
    getServiceByID(Service_ID: any) {
        this.ServiceClient.getServicesCMS({ Service_ID: Service_ID }).subscribe(
            (response: any) => {
                this.Service = response.Services[0];
                console.log(response.Services);
            }
        );
    }
    getServiceName() {
        if (this.translate.currentLang == 'en') {
            return this.Service?.Name_En;
        } else {
            return this.Service?.Name;
        }
    }
    getImgAfter() {
        return this.URLImg + this.Service?.PicUrl1;
    }
    getVideo() {
        return this.Service ? this.URLImg + this.Service?.Video : '';
    }
    getServiceName2() {
        if (this.translate.currentLang == 'en') {
            return this.Service.Add_NameEn;
        } else {
            return this.Service.Add_Name;
        }
    }
    getImgBefore() {
        return this.URLImg + this.Service?.PicUrl2;
    }
    getServiceDescription() {
        if (this.translate.currentLang == 'en') {
            return this.Service?.DescriptionEn;
        } else {
            return this.Service?.Description;
        }
    }

    ngOnInit(): void {}
}
