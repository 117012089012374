<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <aw-wizard
                    #wizard
                    [navBarLayout]="'large-filled-symbols'"
                    [dir]="awWizardDirection"
                >
                    <aw-wizard-step
                        [stepTitle]="translate.instant('chooseclinic')"
                        [navigationSymbol]="{ symbol: awWizardStepSymbol }"
                    >
                        <ng-template awWizardStepSymbol>
                            <i id="step1Title" class="fa fa-check"></i>
                        </ng-template>

                        <div class="row">
                            <div class="col-12">
                                <h2 class="step-title">
                                    {{ translate.instant("clinics") }}
                                </h2>
                            </div>
                        </div>
                        <div class="row justify-content-end mb-3">
                            <div class="col-lg-4 col-md-6">
                                <div class="search-wrapper">
                                    <input
                                        class="form-control"
                                        type="text"
                                        [placeholder]="
                                            translate.instant('searchClinic')
                                        "
                                        [(ngModel)]="searchControl.clinic"
                                        (input)="search()"
                                    />
                                    <i class="bx bx-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="clinicFilterlist.length != 0">
                            <div class="col-12">
                                <div class="clinics_wrapper p-2">
                                    <div
                                        *ngFor="let clinic of clinicFilterlist"
                                        (click)="selectClinic(clinic)"
                                        class="clinic_Box"
                                        [ngClass]="{
                                            selected:
                                                clinic.CodeUnique ==
                                                selectedsteps.clinic.CodeUnique
                                        }"
                                    >
                                        <div class="text">
                                            <h5>{{ getClinicName(clinic) }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-12 mb-2 NoData_Div"
                                *ngIf="clinicFilterlist.length == 0"
                            >
                                <div class="card">
                                    <div class="card-body">
                                        <div class="img">
                                            <img
                                                src="../../../../../assets/img/no-data.png"
                                            />
                                        </div>
                                        <div class="txt mt-3 mb-4">
                                            <p class="mb-0">
                                                {{
                                                    translate.instant("NoData")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12 text-end">
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    (click)="goToServiceStep()"
                                >
                                    {{ translate.instant("Services") }}
                                    <i class="fa fa-arrow-left"></i>
                                </button>

                                <!-- <button type="button" [awGoToStep]="{stepIndex: 2}">Go directly to third Step</button> -->
                            </div>
                        </div>
                    </aw-wizard-step>
                    <aw-wizard-step
                        [stepTitle]="translate.instant('chooseService')"
                        [navigationSymbol]="{ symbol: awWizardStepSymbol }"
                    >
                        <ng-template awWizardStepSymbol>
                            <i id="step2Title" class="fa fa-check"></i>
                        </ng-template>

                        <div class="row">
                            <div class="col-12">
                                <h2 class="step-title">
                                    {{ translate.instant("Services") }}
                                </h2>
                            </div>
                        </div>
                        <div
                            class="row justify-content-end mb-3"
                            *ngIf="serviceOrginallist.length != 0"
                        >
                            <div class="col-lg-4 col-md-6">
                                <div class="search-wrapper">
                                    <input
                                        class="form-control"
                                        type="text"
                                        [placeholder]="
                                            translate.instant('searchService')
                                        "
                                        [(ngModel)]="searchControl.service"
                                        (input)="search()"
                                    />
                                    <i class="bx bx-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="serviceFilterlist.length != 0">
                            <div class="col-12">
                                <div class="doctors_wrapper p-2">
                                    <div
                                        class="doctor_Box"
                                        *ngFor="
                                            let service of serviceFilterlist
                                        "
                                        [ngClass]="
                                            service.ServiceCode ==
                                            selectedsteps.service.ServiceCode
                                                ? 'selected'
                                                : ''
                                        "
                                        (click)="selectService(service)"
                                    >
                                        <div class="header">
                                            <div>
                                                <h6>
                                                    {{
                                                        getServiceName(service)
                                                    }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="body">
                                            <p>
                                                {{ service.ServiceAmount }}
                                            </p>
                                        </div>
                                        <div class="footer mt-4">
                                            <button
                                                class="p-1"
                                                [ngClass]="
                                                    service.ServiceCode ==
                                                    selectedsteps.service
                                                        .ServiceCode
                                                        ? 'default-btn-one'
                                                        : 'default-btn-one-outline'
                                                "
                                                (click)="selectService(service)"
                                            >
                                                <i class="bx bx-check"></i>
                                                {{
                                                    translate.instant(
                                                        "chooseService"
                                                    )
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-12 mb-2 NoData_Div"
                                *ngIf="serviceFilterlist.length == 0"
                            >
                                <div class="card">
                                    <div class="card-body">
                                        <div class="img">
                                            <img
                                                src="../../../../../assets/img/no-data.png"
                                            />
                                        </div>
                                        <div class="txt mt-3 mb-4">
                                            <p class="mb-0">
                                                {{
                                                    translate.instant("NoData")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12 d-flex justify-content-between">
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    awPreviousStep
                                >
                                    <i class="fa fa-arrow-right"></i>
                                    {{ translate.instant("clinics") }}
                                </button>
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    (click)="goToDoctorStep()"
                                >
                                    {{ translate.instant("doctors") }}
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                                <!-- <button type="button" [awGoToStep]="{stepIndex: 2}">Go directly to third Step</button> -->
                            </div>
                        </div>
                    </aw-wizard-step>
                    <aw-wizard-step
                        [stepTitle]="translate.instant('chooseDoctor')"
                        [navigationSymbol]="{ symbol: awWizardStepSymbol }"
                    >
                        <ng-template awWizardStepSymbol>
                            <i id="step2Title" class="fa fa-check"></i>
                        </ng-template>

                        <div class="row">
                            <div class="col-12">
                                <h2 class="step-title">
                                    {{ translate.instant("doctors") }}
                                </h2>
                            </div>
                        </div>
                        <div
                            class="row justify-content-end mb-3"
                            *ngIf="doctorsOrginallist.length != 0"
                        >
                            <div class="col-lg-4 col-md-6">
                                <div class="search-wrapper">
                                    <input
                                        class="form-control"
                                        type="text"
                                        [placeholder]="
                                            translate.instant('searchdoctor')
                                        "
                                        [(ngModel)]="searchControl.doctor"
                                        (input)="search()"
                                    />
                                    <i class="bx bx-search"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="doctorsFilterlist.length != 0">
                            <div class="col-12">
                                <div class="doctors_wrapper p-2">
                                    <div
                                        class="doctor_Box"
                                        *ngFor="let doctor of doctorsFilterlist"
                                        [ngClass]="
                                            doctor.DoctorCode ==
                                            selectedsteps.doctor.DoctorCode
                                                ? 'selected'
                                                : ''
                                        "
                                        (click)="selectDoctor(doctor)"
                                    >
                                        <div class="header">
                                            <img [src]="getDoctorIMG(doctor)" />
                                            <div>
                                                <h6>
                                                    {{ getDoctorName(doctor) }}
                                                </h6>
                                                <p>
                                                    {{
                                                        getClinicDeptName(
                                                            doctor
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="body">
                                            <p>
                                                {{ getSpecialization(doctor) }}
                                            </p>
                                            <div class="contacts">
                                                <a
                                                    href="{{
                                                        'tel:' + doctor.Phone
                                                    }}"
                                                >
                                                    <i
                                                        class="fab fa-whatsapp"
                                                    ></i>
                                                    <span>{{
                                                        doctor.Phone
                                                    }}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="footer mt-4">
                                            <button
                                                class="p-1"
                                                [ngClass]="
                                                    doctor.DoctorCode ==
                                                    selectedsteps.doctor
                                                        .DoctorCode
                                                        ? 'default-btn-one'
                                                        : 'default-btn-one-outline'
                                                "
                                                (click)="selectDoctor(doctor)"
                                            >
                                                <i class="bx bx-check"></i>
                                                {{
                                                    translate.instant(
                                                        "chooseDoctor"
                                                    )
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-12 mb-2 NoData_Div"
                                *ngIf="doctorsFilterlist.length == 0"
                            >
                                <div class="card">
                                    <div class="card-body">
                                        <div class="img">
                                            <img
                                                src="../../../../../assets/img/no-data.png"
                                            />
                                        </div>
                                        <div class="txt mt-3 mb-4">
                                            <p class="mb-0">
                                                {{
                                                    translate.instant("NoData")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-12 d-flex justify-content-between">
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    awPreviousStep
                                >
                                    <i class="fa fa-arrow-right"></i>
                                    {{ translate.instant("Services") }}
                                </button>
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    (click)="goToAppointementStep()"
                                >
                                    {{ translate.instant("Appointments") }}
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                                <!-- <button type="button" [awGoToStep]="{stepIndex: 2}">Go directly to third Step</button> -->
                            </div>
                        </div>
                    </aw-wizard-step>
                    <aw-wizard-step
                        [stepTitle]="translate.instant('chooseApp')"
                        [navigationSymbol]="{ symbol: awWizardStepSymbol }"
                    >
                        <ng-template awWizardStepSymbol>
                            <i id="step3Title" class="fa fa-check"></i>
                        </ng-template>
                        <div class="row">
                            <div class="col-12">
                                <h2 class="step-title">
                                    {{ translate.instant("Appointments") }}
                                </h2>
                            </div>
                        </div>
                        <!-- <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="dates_wrapper">
                                    <div class="inner-wrapper mb-4">
                                        <button
                                            type="button"
                                            role="presentation"
                                            class="date-prev"
                                            (click)="previousDate()"
                                        >
                                            <i class="bx bx-chevron-right"></i>
                                        </button>
                                        <input
                                            type="date"
                                            [(ngModel)]="
                                                selectedsteps.AppointmentDate
                                            "
                                            class="form-control"
                                            (input)="searchAppointment()"
                                             min="{{date | date:'yyyy-MM-dd'}}"
                                        />
                                        <button
                                            type="button"
                                            role="presentation"
                                            class="date-next"
                                            (click)="NextDate()"
                                        >
                                            <i class="bx bx-chevron-left"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-12">
                                <div class="Date_wrapper py-3" >
                                    <div class="date_Box"
                                    (click)="getAppointmentBydate(appointment.Date)"
                                     *ngFor="let appointment of DoctorAppointments"
                                     [ngClass]="
                                            appointment.Date ==
                                            selectedsteps.Date
                                                ? 'selected': ''
                                                "
                                           
                                     >
                                        <h5 class="mb-0">{{appointment.Date}}</h5>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="appointmentList.length != 0">
                            <div class="col-12">
                                <div class="appointments_wrapper">
                                    <div
                                        class="appointment_Box"
                                        *ngFor="
                                            let appointment of appointmentList
                                        "
                                        (click)="selectApp(appointment)"
                                        [ngClass]="
                                            appointment ==
                                            selectedsteps.appointment
                                                ? 'selected'
                                                : ''
                                        "
                                    >
                                        <p class="mb-0">
                                            {{ appointment.Date }}
                                        </p>
                                        <p class="mb-0">
                                            {{ appointment.Appo_Period }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div
                                class="col-12 mb-2 NoData_Div"
                                *ngIf="appointmentList.length == 0"
                            >
                                <div class="card">
                                    <div class="card-body">
                                        <div class="img">
                                            <img
                                                src="../../../../../assets/img/no-data.png"
                                            />
                                        </div>
                                        <div class="txt mt-3 mb-4">
                                            <p class="mb-0">
                                                {{
                                                    translate.instant("NoData")
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 d-flex justify-content-between">
                                <button
                                    class="btn default-btn-one-outline"
                                    type="button"
                                    awPreviousStep
                                >
                                    <i class="fa fa-arrow-right"></i>
                                    {{ translate.instant("doctors") }}
                                </button>

                                <!-- <button type="button" [awGoToStep]="{stepIndex: 2}">Go directly to third Step</button> -->
                            </div>
                        </div>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
        </div>
    </div>
</div>

<div
    id="inserAppModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="inserAppModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("clinic") }}
                            </strong>
                            {{ getClinicName(selectedsteps.clinic) }}
                        </p>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("doctor") }}
                            </strong>
                            {{ getDoctorName(selectedsteps.doctor) }}
                        </p>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("Appdate") }}
                            </strong>
                            {{ selectedsteps.AppointmentDate }}
                        </p>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("Appointement") }}
                            </strong>
                            {{ selectedsteps.appointment.Appo_Period }}
                        </p>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("Service") }}
                            </strong>
                            {{ getServiceName(selectedsteps.service) }}
                        </p>
                    </div>
                    <div class="col-lg-6 mb-2">
                        <p>
                            <strong>
                                {{ translate.instant("ServicePrice") }}
                            </strong>
                            {{ selectedsteps.service.ServiceAmount }}
                        </p>
                    </div>
                </div>
                <hr />
                <div class="row payment_methods">
                    <div class="col-12">
                        <h5 class="text-theme1">
                            {{ translate.instant("ChoosePaymentMethod") }}
                        </h5>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <button
                                    class="p-1 default-btn-two-outline btn-block"
                                    (click)="choosePayType(1)"
                                    [ngClass]="{
                                        selected: selectedsteps.payType == 1
                                    }"
                                >
                                    <img
                                        src="../../../../assets/img/payments/card-payment.png"
                                    />
                                    <span>Card payment</span>
                                </button>
                            </div>
                            <!-- <div class="col-12 mb-2">
                                <button
                                    class="p-1 default-btn-two-outline btn-block"
                                    (click)="choosePayType(2)"
                                    [ngClass]="{
                                            selected:
                                                selectedsteps.payType ==
                                                2
                                        }"
                                >
                                    <img
                                        src="../../../../assets/img/payments/card-installment.png"
                                    />
                                    <span>Bank installment</span>
                                </button>
                            </div> -->
                            <div class="col-12 mb-2">
                                <button
                                    class="p-1 default-btn-two-outline btn-block"
                                    (click)="choosePayType(3)"
                                    [ngClass]="{
                                        selected: selectedsteps.payType == 3
                                    }"
                                >
                                    <img
                                        src="../../../../assets/img/payments/mobile-wallet.png"
                                    />
                                    <span>Mobile wallets</span>
                                </button>
                            </div>
                            <!-- <div class="col-12 mb-2">
                                <button
                                    class="p-1 default-btn-two-outline btn-block"
                                    (click)="choosePayType(4)"
                                    [ngClass]="{
                                            selected:
                                                selectedsteps.payType ==
                                                4
                                        }"
                                >
                                    <img
                                        style="width: 80px"
                                        src="../../../../assets/img/payments/valu.png"
                                    />
                                    <span>ValU</span>
                                </button>
                            </div> -->
                        </div>
                        <div class="row" *ngIf="selectedsteps.payType == ''">
                            <div class="col-12">
                                <p class="text-danger">
                                    برجاء اختيار طريقة للدفع
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button
                    type="button"
                    class="btn btn-sm btn-theme1"
                    (click)="insertAppointment()"
                >
                    {{ translate.instant("confirm") }}
                </button>
                <button
                    type="button"
                    class="btn btn-sm btn-theme1-outline"
                    data-dismiss="modal"
                >
                    {{ translate.instant("cancel") }}
                </button>
            </div>
        </div>
    </div>
</div>
<div
    id="loginFirstModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loginFirstModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <p>
                            {{ translate.instant("loginFirst") }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button
                    type="button"
                    class="btn btn-sm btn-theme1"
                    (click)="goToSignin()"
                >
                    {{ translate.instant("login") }}
                </button>
                <button
                    type="button"
                    class="btn btn-sm btn-theme1-outline"
                    data-dismiss="modal"
                >
                    {{ translate.instant("cancel") }}
                </button>
            </div>
        </div>
    </div>
</div>

<div
    id="MobilePaymentModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="MobilePaymentModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="MobilePaymentModelLabel">
                    {{ translate.instant("MobilePayment") }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form
                [formGroup]="MobilePaymentForm"
                (submit)="onSubmitMobilePaymentForm()"
                novalidate
            >
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                {{ translate.instant("phone") }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="phone"
                                    phone
                                    [placeholder]="translate.instant('phone')"
                                />
                            </div>
                            <div
                                *ngIf="
                                    MobilePaymentForm.controls['phone']
                                        .invalid &&
                                    (MobilePaymentForm.controls['phone']
                                        .dirty ||
                                        MobilePaymentForm.controls['phone']
                                            .touched)
                                "
                                class="alert alert-danger"
                            >
                                <div
                                    *ngIf="
                                        MobilePaymentForm.controls['phone']
                                            .errors.required
                                    "
                                >
                                    {{ translate.instant("phoneRequired") }}
                                </div>
                                <div
                                    *ngIf="
                                        MobilePaymentForm.controls['phone']
                                            .errors.pattern
                                    "
                                >
                                    {{
                                        translate.instant(
                                            "Patient_Mobile_Formate"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ translate.instant("close") }}
                    </button>
                    <button
                        type="submit"
                        [disabled]="!MobilePaymentForm.valid"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("submit") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
