import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { ClinicsComponent } from './components/pages/clinics/clinics.component';
import { AppointmentsComponent } from './components/pages/_Appointments/appointments/appointments.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { VisitDetailsComponent } from './components/pages/_Appointments/visit-details/visit-details.component';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { ArticlesComponent } from './components/pages/articles/articles.component';
import { VacationsComponent } from './components/pages/vacations/vacations.component';
import { VaccinationsComponent } from './components/pages/vaccinations/vaccinations.component';
import { PaymentRequestMsgComponent } from './components/pages/PaymentRequestMsg/PaymentRequestMsg.component';
import { ArticleDetailsComponent } from './components/pages/article-details/article-details.component';
import { MedTeamComponent } from './components/pages/med-team/med-team.component';
import { MedTeamDetailsComponent } from './components/pages/med-team-details/med-team-details.component';

import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';

const routes: Routes = [
    { path: '', component: HomeOneComponent },
    { path: 'home-one', component: HomeOneComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'clinics', component: ClinicsComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'appointments', component: AppointmentsComponent },
    { path: 'visit_details', component: VisitDetailsComponent },
    { path: 'packages', component: PackagesComponent },
    { path: 'articles', component: ArticlesComponent },
    { path: 'article-details/:id', component: ArticleDetailsComponent },
    { path: 'vacations', component: VacationsComponent },
    { path: 'vaccinations', component: VaccinationsComponent },
    { path: 'payment', component: PaymentRequestMsgComponent },
    { path: 'medTeam', component: MedTeamComponent },
    { path: 'MedTeam-details/:id', component: MedTeamDetailsComponent },
    { path: 'service-details/:id', component: ServiceDetailsComponent },
    { path: 'home-two', component: HomeTwoComponent },

    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
