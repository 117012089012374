import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SMSService } from '../../../Services/SMSService';

@Component({
    selector: 'app-PaymentRequestMsg',
    templateUrl: './PaymentRequestMsg.component.html',
    styleUrls: ['./PaymentRequestMsg.component.scss'],
})
export class PaymentRequestMsgComponent implements OnInit {
    private httpClient: HttpClient;

    constructor(
        private route: ActivatedRoute,
        public translate: TranslateService,
        private SMSService: SMSService,
        httpBackend: HttpBackend
    ) {
        this.httpClient = new HttpClient(httpBackend);
    }
    success: boolean = false;
    ngOnInit() {
        this.route.queryParamMap.subscribe((params) => {
            const success = params.get('success').toString();
            this.success = success?.toString() == 'true';
            const merchant_order_id = params
                .get('merchant_order_id')
                .toString();
            if (this.success) { 
                this.SMSService.CallBack_Pay({
                    obj: {
                        success: true,
                        order:{ merchant_order_id: merchant_order_id },
                    },
                }).subscribe((response) => {});
            }

        });
    }
}
