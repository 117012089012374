<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>
                        {{ translate.instant("login") }}
                    </h2>
                    <ul>
                        <li>
                            <a routerLink="/">
                                {{ translate.instant("MainPage") }}
                            </a>
                        </li>
                        <li>{{ translate.instant("login") }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <img src="assets/img/logo.png" alt="logo" />
            <p>{{ translate.instant("JoinUs") }}</p>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="loginForm" (submit)="onSubmit()" novalidate>
                <div class="form-group">
                    <input
                        class="form-control"
                        type="text"
                        formControlName="phone"
                        phone
                        maxlength="16"
                        [placeholder]="translate.instant('phone')"
                    />
                </div>
                <div
                    *ngIf="
                        loginForm.controls['phone'].invalid &&
                        (loginForm.controls['phone'].dirty ||
                            loginForm.controls['phone'].touched)
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="loginForm.controls['phone'].errors.required">
                        {{ translate.instant("phoneRequired") }}
                    </div>
                    <div
                                *ngIf="
                                    loginForm.controls['phone'].errors
                                        .pattern
                                "
                            >
                                {{
                                    translate.instant("Patient_Mobile_Formate")
                                }}
                            </div>
                    

                </div>
                <div class="row mb-5">
                    <!-- <div class="col-sm-6">
                        <p class="account-decs right">
                            <a
                                data-toggle="modal"
                                data-target="#ForgetPasswordModal"
                            >
                                {{ translate.instant("ForgetPassword") }}
                            </a>
                        </p>
                    </div> -->

                </div>
                <div class="row ">
<div class="col-12 d-flex justify-content-between align-items-center">
                <p class="account-decs">
                    <a routerLink="/sign-up">
                        {{ translate.instant("NewAccount") }}
                    </a>
                </p>
                <div class="">
                    <button
                        type="submit"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("login") }}
                    </button>
                </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->

<div
    id="BranchModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="BranchModelLabel"
    aria-hidden="true"
>
<app-branches></app-branches>
</div>



<div
    id="VerficationCodeModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="VerficationCodeModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="VerficationCodeModelLabel">
                    {{ translate.instant("VerficationCodeTosignin") }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form
                [formGroup]="VerficationCodeForm"
                (submit)="onSubmitVerficationCodeForm()"
                novalidate
            >
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                {{ translate.instant("VerficationCode") }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="VerficationCode"
                                    VerficationCode
                                    [placeholder]="translate.instant('VerficationCode')"
                                />
                            </div>
                            <div
                                *ngIf="
                                    VerficationCodeForm.controls['VerficationCode'].invalid &&
                                    (VerficationCodeForm.controls['VerficationCode'].dirty ||
                                        VerficationCodeForm.controls['VerficationCode'].touched)
                                "
                                class="alert alert-danger"
                            >
                                <div
                                    *ngIf="
                                        VerficationCodeForm.controls['VerficationCode'].errors
                                            .required
                                    "
                                >
                                    {{ translate.instant("VerficationCodeRequired") }}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">

                    <button
                        type="submit"
                        [disabled]="!VerficationCodeForm.valid"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("submit") }}
                    </button>
                    <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {{ translate.instant("close") }}
                </button>
                </div>
            </form>
        </div>
    </div>
</div>
