import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountClient } from 'src/app/Services/accountService.service';
import swal from 'sweetalert';
import { TranslateService } from '@ngx-translate/core';
import {
    getPatient,
    saveUserInfoToStorage,
} from '../../../Functions/Utilities';
declare var $: any;

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
    SignUpForm: FormGroup;
    SexList = [{ Type: 'Male' }, { Type: 'Female' }];
    file: File = null;
    ImageURL: any;


    constructor(
        private fb: FormBuilder,
        private accountClient: AccountClient,
        private route: Router,
        public translate: TranslateService,
    ) {}
    ngOnInit(): void {
        this.createForm();
    }
   
    createForm() {
        this.SignUpForm = this.fb.group(
            {
                Patient_FirstName_Ar: ['', [Validators.required]],
                Patient_FatherName_Ar: '',
                Patient_MiddleName_Ar: '',
                Patient_LastName_Ar: ['', [Validators.required]],
                Patient_Name_Ar: '',
                Patient_FirstName_En: '',
                Patient_FatherName_En: '',
                Patient_MiddleName_En: '',
                Patient_LastName_En: '',
                Patient_Name_En: '',
                Sex: ['', [Validators.required]],
                Patient_BirthDate: ['', [Validators.required]],
                Patient_Mobile: [
                    '',
                    [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
                ],
                 User_Name: '',
                 Password: '',
                //ConfirmedPassword: ['', Validators.required],
                IMG: '',
                Patient_Phone: '',
                Email: ['', [Validators.required, Validators.email]],
                CountryCode: '',
                ID_Number: [
                    '',
                    [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
                ],
            }
        );
    }
   
    onSubmit() {
         if (this.SignUpForm.valid) {
             this.SignUpForm.value.Patient_Name_Ar =
                 this.SignUpForm.value.Patient_FirstName_Ar;

             this.accountClient
                 .account_patient(this.SignUpForm.value)
                 .subscribe((responsePatient: any) => {
                     if (responsePatient.Success) {
                         if (this.file != null) {
                             const formData = new FormData();
                             formData.append(this.file.name, this.file);
                             this.accountClient
                                 .account_UpdatePatientIMG(
                                     formData,
                                     responsePatient.Result?.patient[0]
                                         .Patient_Code
                                 )
                                 .subscribe((response: any) => {
                                     saveUserInfoToStorage(
                                         responsePatient.Result,
                                         response.Image
                                     );
                                $('#BranchModel').modal('show');
                                     

                                    //  swal({
                                    //      title: this.translate.instant(
                                    //          'loginSuccessfully'
                                    //      ),
                                    //      text: this.translate.instant(
                                    //          'GotoMainPage'
                                    //      ),
                                    //      icon: 'success',
                                    //      timer: 1000,
                                    //      buttons: {},
                                    //  }).then(
                                    //      () => {
                                    //          // this.route.navigate(['home-one']);
                                    //          location.href = '/home-one';
                                    //      },
                                    //      (dismiss) => {
                                    //          if (dismiss === 'timer') {
                                    //          }
                                    //      }
                                    //  );
                                 });
                         } else {
                             saveUserInfoToStorage(
                                 responsePatient.Result,
                                 responsePatient.Result.patient[0].IMG
                             );
                              $('#BranchModel').modal('show');
                                
                            //  swal({
                            //      title: this.translate.instant(
                            //          'loginSuccessfully'
                            //      ),
                            //      text: this.translate.instant('GotoMainPage'),
                            //      icon: 'success',
                            //      timer: 1000,
                            //      buttons: {},
                            //  }).then(
                            //      () => {
                            //          //  this.route.navigate(['home-one']);
                            //          location.href = '/home-one';
                            //      },
                            //      (dismiss) => {
                            //          if (dismiss === 'timer') {
                            //          }
                            //      }
                            //  );
                         }
                     } else {
                         swal({
                             title: this.translate.instant('loginFailed'),
                             text: this.translate.instant(
                                 responsePatient.Result
                             ),
                             icon: 'error',
                             timer: 2000,
                             buttons: {},
                         });
                     }
                 });
         } else {
             Object.keys(this.SignUpForm.controls).forEach((field) => {
                 const control = this.SignUpForm.get(field);
                 control.markAsTouched({ onlySelf: true });
             });
         }
    }
    onChange(event) {
        this.file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
            this.ImageURL = reader.result;
        };
    }
}
