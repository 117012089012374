<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>اسم الطبيب</h2>
                    <ul>
                        <li><a routerLink="/">{{translate.instant('MainPage')}}</a></li>
                        <li>{{translate.instant('MedTeamDetails')}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<div class="blog-details-area bg-f9f9f9 ptb-50">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img [src]="getDoctorImg(Doctor)" alt="image">
                    </div>

                    <div class="article-content">
                         <p>
                           {{getDoctorName(Doctor)}}

                        </p>
                         <div [innerHTML]="getDescription(Doctor)"> </div>
                       
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>

                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>

                    <div class="widget widget_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <div class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">The Data Surrounding Higher Education</a></h4>
                            </div>
                            <div class="clear"></div>
                        </div>

                        <div class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Conversion Rate the Sales Funnel Optimization</a></h4>
                            </div>
                            <div class="clear"></div>
                        </div>

                        <div class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Business Data is changing the world’s Energy</a></h4>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog">Aike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Blog Details Area -->
