// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    Username: 'AppProfessorsElThawra',
    Password: '>4@y}eRo$Lk;P+th',
    ProviderId: '5b7596f1-565f-4b5f-b1bb-8b31a9f07632',
    BranchId: 32,
    org: 27,
};
// export const environment = {
//     production: false,
//     Username: 'ApptFarabi',
//     Password: 'ApptFarabi295',
//     ProviderId: '5b7596f1-565f-4b5f-b1bb-8b31a9f076ea',
//     BranchId: 1,
//     org: 1,
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
