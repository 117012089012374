import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
    Services!: any[];
    URLImg: any =
        'https://profadmin.careofme.net/Uploads/Service/';
    constructor(
        public translate: TranslateService,
        private ServiceClient: WaClient,
        private route: Router
    ) {
        this.getServices();
    }
    getServices() {
        this.ServiceClient.getServicesCMS({ Service_ID: null }).subscribe(
            (response: any) => {
                this.Services = response.Services;
                console.log(response.Services);
            }
        );
    }
    getServiceName(Service: any) {
        if (this.translate.currentLang == 'en') {
            return Service.Name_En;
        } else {
            return Service.Name;
        }
    }

    gotoServiceDetails(id: any) {
        this.route.navigate(['service-details', id]);
    }
    getDoctorImg(Service: any) {
        return this.URLImg + Service.PicUrl1;
    }

    ngOnInit(): void {}
}
