import {
    Component,
    Input,
    NgZone,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { empty, merge, of, timer, Subscription } from 'rxjs';
import { delay, expand, map, takeWhile } from 'rxjs/operators';
import { LocalStorageEnums } from '../../../../Enums/LocalStorageEnums.enum';
import { sessionIsExpired } from '../../../../Functions/Utilities';
import { Router } from '@angular/router';
declare var $: any;
enum TimerType {
    WARNING,
    EXPIRED,
}

interface TimerEvent {
    type: TimerType;
    timeLeft: number;
}

@Component({
    selector: 'app-session-timeout',
    templateUrl: './session-timeout.component.html',
    styleUrls: ['./session-timeout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class SessionTimeoutComponent implements OnInit {


    sessionDuration =
       ( new Date(
            localStorage.getItem(LocalStorageEnums.SessionExpireDate)!
        ).getTime() +
        Number(localStorage.getItem(LocalStorageEnums.sessionDuration) ?? 0) *
            60000 )-
        Date.now();
    warningAmount = 60000;
    addClass:boolean = true;
    warningDuration = this.sessionDuration - this.warningAmount;
    showWarningLogin: boolean = false;
    endEpoch!: number;
    timerOutput!: any[];
    counter!: number;
    rtlEnabled: boolean;
    language: any;
    timeoutSub!: Subscription;
    loginButtonOptions: { text: string; class: string; onClick(e: any): void };
    // language: string;
    IsSessionVisable: boolean = true;

    constructor(
        private readonly ngZone: NgZone,
        public translate: TranslateService,
        private route: Router
    ) {
        translate.addLangs(['en', 'ar']);
        this.language =
            localStorage.getItem(LocalStorageEnums.NitcotekTalebcomLanguage) ??
            '';
        if (this.language == '') this.language = 'ar';
        translate.setDefaultLang(this.language);
        translate.use(this.language);
        document.dir = this.language == 'ar' ? 'rtl' : 'ltr';
        if (document.dir == 'rtl') {
            this.rtlEnabled = true;
        } else {
            this.rtlEnabled = false;
        }
        if (sessionIsExpired()) {
            $('#sessionDuration').modal('hide');
             this.addClass = true;
        }

    }

    ngOnInit(): void {
        this.startTimer();
        this.dragElement(document.getElementById('sessionDuration_preview'))
    }

    private startTimer(): void {
        let SessionExpireDate = new Date(
            localStorage.getItem(LocalStorageEnums.SessionExpireDate)!
        );
        let sessionDuration = Number(
            localStorage.getItem(LocalStorageEnums.sessionDuration) ?? 0
        );
        this.endEpoch = SessionExpireDate.getTime() + sessionDuration * 60000;

        const targetDate: string = new Date(this.endEpoch).toISOString();
        console.log(`Target: ${SessionExpireDate}`);
        const warning$: Observable<TimerEvent> = this.getWarning$();
        const expire$: Observable<TimerEvent> = this.getExpiration$();
        const timeout$: Observable<TimerEvent> = merge(warning$, expire$);
        this.timerOutput = [];

        this.ngZone.runOutsideAngular(() => {
            this.timeoutSub = timeout$.subscribe(
                (timerEvent: TimerEvent) => {
                    const dateStr: string = new Date().toISOString();
                    this.ngZone.run(() => {
                        this.counter = timerEvent.timeLeft;
                        let differtime =
                            new Date(
                                localStorage.getItem(
                                    LocalStorageEnums.SessionExpireDate
                                )!
                            ).getTime() +
                            Number(
                                localStorage.getItem(
                                    LocalStorageEnums.sessionDuration
                                ) ?? 0
                            ) *
                                60000 -
                            Date.now();

                        if (!sessionIsExpired() && differtime > 60000) {
                            $('#sessionDuration').modal('hide');
                            this.addClass = true;
                        }
                        if (!sessionIsExpired() && !this.showWarningLogin) {
                            $('#sessionDuration').modal('show');
                            this.timerOutput.push({
                                date: dateStr,
                                ...timerEvent,
                            });
                        }
                        if (sessionIsExpired()) {
                            $('#sessionDuration').modal('hide');
                            this.addClass = true;
                        }
                    });
                },
                (err) => {},
                () => {
                    if (!this.route.routerState.snapshot.url.includes('payment')) {
                        $('#sessionDuration').modal('hide');
                        localStorage.clear();
                        this.route.navigate(['home-one']);
                    }
                }
            );
        });
    }

    private getWarning$(): Observable<TimerEvent> {
        return timer(this.warningDuration).pipe(
            expand(() => {
                const timeLeft: number = this.getTimeLeft();
                return timeLeft > 0
                    ? of(undefined).pipe(delay(this.getDelay(timeLeft)))
                    : empty();
            }),
            map(() => this.getTimeLeft()),
            takeWhile((timeLeft) => timeLeft > 0),
            map((timeLeft) => {
                timeLeft = Math.ceil(timeLeft / 1000);
                return {
                    type: TimerType.WARNING,
                    timeLeft,
                };
            })
        );
    }
    private getExpiration$(): Observable<TimerEvent> {
        return timer(this.sessionDuration).pipe(
            map(() => {
                return {
                    type: TimerType.EXPIRED,
                    timeLeft: 0,
                };
            })
        );
    }
    private getTimeLeft(): number {
        return this.endEpoch - Date.now();
    }
    private getDelay(timeLeft: number): number {
        return timeLeft % 1000; // this is the milliseconds portion of the time difference
    }
    ShowCounterPreview(){
      // document.getElementById('sessionDuration').display ='none';
        this.addClass = !this.addClass;
        this.showWarningLogin = true;
        $('#sessionDuration').modal('hide');

      }
      ////
      // Make the DIV element draggable:

 dragElement(elmnt)  {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

    elmnt.onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

}
