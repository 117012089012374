import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit {
    Topics: any[] = [];
    constructor(
        public translate: TranslateService,
        private articlesClient: WaClient,
        private route: Router
    ) {
        this.getArticles();
    }
    gotoArticleDetails(id:any) {
        this.route.navigate([
            'article-details',
            id,
        ]);
    }
    getArticles() {
        this.articlesClient
            .wa_GetTopics({ TopicId: null })
            .subscribe((response: any) => {
                this.Topics = response.Topics;
                console.log(response);
            });
    }
    ngOnInit(): void { }
    getTitle(Topic:any) { 
        if (this.translate.currentLang == 'en') {
            return Topic.Topics_Title_En;
        }
        else { 

           return Topic.Topics_Title_Ar;
        }

    }
}
