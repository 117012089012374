<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{translate.instant('MedTeam')}}</h2>
                    <ul>
                        <li><a routerLink="/">{{translate.instant('MainPage')}}</a></li>
                        <li>{{translate.instant('MedTeam')}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Our Services Area -->
<div class="our-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>{{translate.instant('OurMedTeam')}}</span>
            <h2>{{translate.instant('MedTeamWord')}}</h2>
        </div>

       <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let Doctor of Doctors">
            <div class="service-card-two">
                <img [src]="getDoctorImg(Doctor)" alt="image">
                <div class="service-caption">
                    <h3>{{getDoctorName(Doctor)}}</h3>
                    <!-- <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the system.</p> -->
                    
                   <a (click)="gotoDoctorDetails(Doctor.Staff_ID)"
                    class="default-btn-two">{{translate.instant('ReadMore')}}</a>

                </div>
            </div>
        </div>
       
           
        </div>
    </div>
</div>
<!-- Our End Services Area -->


