<!-- Header Area -->
<header class="header-area">
    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"
                            ><img src="assets/img/logo.png" alt="logo"
                        /></a>
                        <div class="d-flex align-items-center">
                            <a class="mx-2 language" (click)="changeLanguage()">
                                <i
                                    class="fa fa-globe"
                                    alt="language"
                                    title="language"
                                ></i>
                            </a>
                            <a
                                class="default-btn-one p-2"
                                routerLink="/sign-in"
                            >
                                {{ translate.instant("login") }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"
                        ><img src="assets/img/logo.png" alt="logo"
                    /></a>

                    <div
                        class="collapse navbar-collapse mean-menu justify-content-center"
                    >
                        <ul class="navbar-nav">
                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Home </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 1</a></li>

                                    <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 2</a></li>

                                    <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Demo - 3</a></li>
                                </ul>
                            </li> -->
                            <li class="nav-item">
                                <a
                                    routerLink="/"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >
                                    {{ translate.instant("MainPage") }}
                                    </a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/clinics"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('clinics')}}</a
                                >
                            </li>
                            <li class="nav-item" *ngIf="!sessionIsExpired()">
                                <a
                                    routerLink="/appointments"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Appointments')}}</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/packages"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Packages')}}</a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/articles"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Articles')}}</a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/medTeam"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('MedTeam')}}</a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/services"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Services')}}</a>
                            </li>
                            <li class="nav-item"  *ngIf="!sessionIsExpired()">
                                <a
                                    routerLink="/vaccinations"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Vaccinations')}}</a
                                >
                            </li>
                            <li class="nav-item"  *ngIf="!sessionIsExpired()">
                                <a
                                    routerLink="/vacations"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('Vacations')}}</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/contact"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >{{translate.instant('ContactUs')}}</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex align-items-center">
                        <a class="mx-2 language" (click)="changeLanguage()">
                            <i
                                class="fa fa-globe"
                                alt="language"
                                title="language"
                            ></i>
                        </a>

                        <button
                            *ngIf="sessionIsExpired()"
                            class="default-btn-one"
                            routerLink="sign-in"
                        >
                            {{ translate.instant("login") }}
                        </button>

                        <div class="nav-item" *ngIf="!sessionIsExpired()">
                            <a href="javascript:void(0)" class="nav-link">
                                 {{getPatientName()}}
                                 <i class='bx bx-chevron-down'></i>
                                </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a class="nav-link username" routerLink="/profile" >
                                        <img [src]="getPatientIMG()"/>
                                        {{ translate.instant("profile") }}
                                     </a>
                                 </li>
                                <li class="nav-item">
                                    <a class="nav-link logout text-danger" (click)="signout()">
                                        <i class="bx bx-log-out"></i>
                                    {{ translate.instant("logout") }}
                                     </a>
                                 </li>

                            </ul>
                        </div>

                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- End Header Area -->
