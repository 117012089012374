import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountClient } from '../../../Services/accountService.service';
import { SMSService } from '../../../Services/SMSService';
import swal from 'sweetalert';
import { TranslateService } from '@ngx-translate/core';
import { saveUserInfoToStorage } from '../../../Functions/Utilities';
import { HttpBackend, HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
    loginForm: FormGroup;
    VerficationCodeForm: FormGroup;
    SMSData: any;
    Branchs: any[] = [];
    private httpClient: HttpClient;
    @ViewChild('VerficationCodeModel') VerficationCodeModel;

    constructor(
        private fb: FormBuilder,
        private accountClient: AccountClient,
        private SMSService: SMSService,
        public translate: TranslateService,
        httpBackend: HttpBackend
    ) {
        this.httpClient = new HttpClient(httpBackend);
    }

    ngOnInit(): void {
        this.createForm();
    }
    createForm() {
        this.loginForm = this.fb.group({
            phone: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
            ],
        });
        this.VerficationCodeForm = this.fb.group({
            VerficationCode: ['', [Validators.required]],
        });
    }
    onSubmitVerficationCodeForm() {
        const VerficationCode = this.VerficationCodeForm.value?.VerficationCode;

        if (VerficationCode == this.SMSData.Verfication_code) {
            this.accountClient
                .account_SendCodeViaPhoneSM(this.SMSData)
                .subscribe((response: any) => {
                    $('#VerficationCodeModel').modal('hide');
                    if (response.Success) {
                        saveUserInfoToStorage(
                            response.Result,
                            response.Result.patient[0].IMG
                        );
                        $('#BranchModel').modal('show');
                    } else {
                        swal({
                            title: this.translate.instant('loginFailed'),
                            text: this.translate.instant(response.Result),
                            icon: 'error',
                            timer: 2000,
                            buttons: {},
                        });
                    }
                });
        } else {
            swal({
                title: this.translate.instant('loginFailed'),
                text: this.translate.instant('VerficationCodeError'),
                icon: 'error',
                timer: 2000,
                buttons: {},
            });
        }
    }

    generateOTP() {
        let digits = '0123456789';
        let OTP = '';
        let len = digits.length;
        for (let i = 0; i < 4; i++) {
            OTP += digits[Math.floor(Math.random() * len)];
        }

        return OTP;
    }
    onSubmit() {
        if (this.loginForm.valid) {
            this.accountClient
                .account_GetPatientByMobile({
                    phone: this.loginForm.value.phone,
                })
                .subscribe((responsephone) => {
                    if (responsephone.patient.length > 0) {
                        this.SMSService.getAuthKeyFromSmsSevice().subscribe(
                            (responseToken: any) => {
                                let token = responseToken.check.data.token;
                                // let otpCode = this.generateOTP();
                                let SMsMsg = {
                                    phone: this.loginForm.value.phone,
                                    token: token,
                                };

                                this.accountClient
                                    .SendSMSAsync(SMsMsg)
                                    .subscribe(
                                        (response: any) => {
                                            
                                            if (response.IsSuccessStatusCode) {
                                           
                                                this.SMSData = {
                                                    Verfication_code:
                                                        response.VerificationCodeNumber,
                                                    phone: this.loginForm.value
                                                        .phone,
                                                };
                                                $(
                                                    '#VerficationCodeModel'
                                                ).modal('show');
                                            } else {
                                                swal({
                                                    title: this.translate.instant(
                                                        'errorInSMSService'
                                                    ),
                                                    text: this.translate.instant(
                                                        'checkSupport'
                                                    ),
                                                    icon: 'error',
                                                    timer: 3000,
                                                    buttons: {},
                                                });
                                            }
                                        },
                                        (e: any) => {
                                            swal({
                                                title: this.translate.instant(
                                                    'errorInSMSService'
                                                ),
                                                text: this.translate.instant(e),
                                                icon: 'error',
                                                timer: 2000,
                                                buttons: {},
                                            });
                                        }
                                    );
                            }
                        );
                    } else {
                        swal({
                            title: this.translate.instant('loginFailed'),
                            text: this.translate.instant('PhoneNotExistBefore'),
                            icon: 'error',
                            timer: 2000,
                            buttons: {},
                        });
                    }
                });
        } else {
            Object.keys(this.loginForm.controls).forEach((field) => {
                const control = this.loginForm.get(field);
                control.markAsTouched({ onlySelf: true });
            });
        }
    }
}

// "Email":"saraa_salem765@yahoo.con",
//     "Password":"123"
