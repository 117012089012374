<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>
                        {{ translate.instant("MyAccount") }}
                    </h2>
                    <ul>
                        <li><a routerLink="/">
                             {{ translate.instant("MainPage") }}
                        </a></li>
                        <li>
                            {{ translate.instant("MyAccount") }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
           <img src="assets/img/logo.png" alt="logo">
            <p>
                {{ translate.instant("GotoMainPage") }}
            </p>
        </div>

        <div class="sign-in-form sign-up-form">
            <form [formGroup]="ProfileForm" (submit)="onSubmit()" novalidate>
                <div class="row">
                    <div class="col-12">
                        <input type="file" style="display: none" #selectFile
                        (change)="onChange($event)"/>
                        <div class="img-wrapper" (click)="selectFile.click()">


                            <a >
                                <img for="selectFile"
                                  [src]="ImageURL"
                                  />
                                  </a>

                                                    <i class="fa fa-camera"></i>
                                                    <div class="overlay"></div>
                                                   </div>

                    </div>
                </div>
                <div class="row ">

                    <div class="col-lg-6">
                        <div class="form-group">
                             <input class="form-control" type="text"
                              formControlName="Patient_FirstName_Ar" class="form-control" [placeholder]="translate.instant('Patient_FirstName_Ar')+ '*'">
                            </div>
                        <div *ngIf="ProfileForm.controls['Patient_FirstName_Ar'].invalid &&
                             (ProfileForm.controls['Patient_FirstName_Ar'].dirty || ProfileForm.controls['Patient_FirstName_Ar'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="ProfileForm.controls['Patient_FirstName_Ar'].errors.required">
                                {{ translate.instant("Patient_FirstName_Required") }}
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" class="form-control"  formControlName="Patient_LastName_Ar" [placeholder]="translate.instant('Patient_LastName')+ '*'">
                            </div>
                            <div *ngIf="ProfileForm.controls['Patient_LastName_Ar'].invalid &&
                                (ProfileForm.controls['Patient_LastName_Ar'].dirty || ProfileForm.controls['Patient_LastName_Ar'].touched)"
                                class="alert alert-danger">
                                <div *ngIf="ProfileForm.controls['Patient_LastName_Ar'].errors.required">
                                    {{ translate.instant("Patient_LastName_Required") }}
                                </div>
                            </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="number" class="form-control" formControlName="ID_Number" [placeholder]="translate.instant('ID_Number')+ '*'">
                                </div>
                                <div *ngIf="ProfileForm.controls['ID_Number'].invalid &&
                                  (ProfileForm.controls['ID_Number'].dirty || ProfileForm.controls['ID_Number'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="ProfileForm.controls['ID_Number'].errors.required">
                                        {{translate.instant('ID_Number_required')}}
                                    </div>
                                    <div *ngIf="ProfileForm.controls['ID_Number'].errors.pattern">
                                        {{translate.instant('ID_NumberInvalidLength')}}
                                    </div>
                                </div>

                                </div>


                                
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="date" class="form-control" formControlName="Patient_BirthDate" [placeholder]="translate.instant('Patient_BirthDate')+ '*'">
                                    </div>
                                    <div *ngIf="ProfileForm.controls['Patient_BirthDate'].invalid &&
                                    (ProfileForm.controls['Patient_BirthDate'].dirty || ProfileForm.controls['Patient_BirthDate'].touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="ProfileForm.controls['Patient_BirthDate'].errors.required">
                                           {{translate.instant('Patient_BirthDate_required')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="number" class="form-control" formControlName="Patient_Mobile" [placeholder]="translate.instant('Patient_Mobile')+ '*'">
                                    </div>
                                    <div *ngIf="ProfileForm.controls['Patient_Mobile'].invalid &&
                                        (ProfileForm.controls['Patient_Mobile'].dirty || ProfileForm.controls['Patient_Mobile'].touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="ProfileForm.controls['Patient_Mobile'].errors.required">
                                            {{translate.instant('Patient_Mobile_required')}}
                                        </div>
                                        <div *ngIf="ProfileForm.controls['Patient_Mobile'].errors.pattern">
                                            {{translate.instant('Patient_Mobile_required')}}
                                           </div>
                                    </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                        <input class="form-control" type="text" formControlName="Email" [placeholder]="translate.instant('Email')+ '*'">

                                        </div>
                                        <div *ngIf="ProfileForm.controls['Email'].invalid &&
                                                    (ProfileForm.controls['Email'].dirty || ProfileForm.controls['Email'].touched)" class="alert alert-danger">
                                            <div *ngIf="ProfileForm.controls['Email'].errors.required">
                                               {{translate.instant('EmailRequired')}}
                                            </div>
                                            <div *ngIf="ProfileForm.controls['Email'].errors.email">
                                                 {{translate.instant('EmailWrongFormate')}}
                                            </div>
                                        </div>

                                        </div>
                                        <!-- <div class="col-lg-6">
                                            <div class="form-group">
                                            <input type="password" class="form-control"  formControlName="Password"  [placeholder]="translate.instant('Password')">
                                            </div>
                                            <div *ngIf="ProfileForm.controls['Password'].invalid &&
                                                                        (ProfileForm.controls['Password'].dirty || ProfileForm.controls['Password'].touched)"
                                                class="alert alert-danger">
                                                <div *ngIf="ProfileForm.controls['Password'].errors.required">
                                                    {{translate.instant('PasswordRequired')}}
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">

                                            <input type="password" class="form-control"  formControlName="ConfirmedPassword"  [placeholder]="translate.instant('ConfirmedPassword')">

                                                </div>
                                                <div *ngIf="ProfileForm.controls['ConfirmedPassword'].invalid &&
                                                (ProfileForm.controls['ConfirmedPassword'].dirty ||
                                                ProfileForm.controls['ConfirmedPassword'].touched)"
                                                    class="alert alert-danger">
                                                    <div *ngIf="ProfileForm.controls['ConfirmedPassword'].errors.required">
                                                         {{translate.instant('ConfirmedPasswordRequired')}} </div>

                                                    <div *ngIf="ProfileForm.controls['ConfirmedPassword'].errors.confirmedValidator">
                                                        {{translate.instant('ConfirmedPasswordNotIdentical')}} </div>
                                                </div>


                                                </div> -->
                    </div>

                <div class="text-end">
                  <button type="submit" class="btn default-btn-one"> {{translate.instant('save')}}</button>

                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->

