<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{translate.instant('Articles')}}</h2>
                    <ul>
                        <li><a routerLink="/">{{translate.instant('MainPage')}}</a></li>
                        <li>{{translate.instant('Articles')}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Blog Area -->
<div class="blog-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <span>{{translate.instant('Articles')}}</span>
            <h2>{{translate.instant('ReadOurLatestArticles')}}</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Topic of Topics">
                <div   class="blog-card">
                    <div class="blog-text">
                        <!-- <span class="meta-tag">Delivery</span> -->
                        <div class="date">{{Topic.Topics_TimeStmap}}</div>
                        <h3>{{getTitle(Topic)}}</h3>
                        <a (click)="gotoArticleDetails(Topic.Topics_ID)" class="default-btn-two">{{translate.instant('ReadMore')}}</a>
                    </div>
                </div>
            </div>

            <!-- Pagination -->
            <!-- <div class="col-lg-12 col-md-12">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" routerLink="/articles"><span aria-hidden="true">&laquo;</span></a></li>
                    <li class="page-item active"><a class="page-link" routerLink="/articles">1</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/articles">2</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/articles">3</a></li>
                    <li class="page-item"><a class="page-link" routerLink="/articles"><span aria-hidden="true">&raquo;</span></a></li>
                </ul>
            </div> -->
        </div>
    </div>
</div>
<!-- End Blog Area -->
