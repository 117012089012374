import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';

@Component({
    selector: 'app-med-team',
    templateUrl: './med-team.component.html',
    styleUrls: ['./med-team.component.scss'],
})
export class MedTeamComponent implements OnInit {
    Doctors!: any[];
    URLImg: any = 'https://profadmin.careofme.net/Uploads/Staff/';
    constructor(
        public translate: TranslateService,
        private DoctorClient: WaClient,
        private route: Router
    ) {
        this.getDoctors();
    }
    getDoctors() {
        this.DoctorClient.wa_getDoctorsCMS({ Staff_ID: null }).subscribe(
            (response: any) => {
                this.Doctors = response.Doctors;
            }
        );
    }
    getDoctorName(Doctor: any) {
        if (this.translate.currentLang == 'en') {
            return Doctor.Staff_Name_En;
        } else {
            return Doctor.Staff_Name_Ar;
        }
    }
    gotoDoctorDetails(id: any) {
        this.route.navigate(['MedTeam-details', id]);
    }
    getDoctorImg(Doctor: any) {
        return this.URLImg + Doctor.Staff_PicUrl;
    }
    ngOnInit(): void {}
}
