import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WaClient } from 'src/app/Services/Wa.service';

@Component({
    selector: 'app-med-team-details',
    templateUrl: './med-team-details.component.html',
    styleUrls: ['./med-team-details.component.scss'],
})
export class MedTeamDetailsComponent implements OnInit {
    Doctor!: any;
    URLImg: any = 'https://profadmin.careofme.net/Uploads/Staff/';
    constructor(
        public translate: TranslateService,
        private DoctorClient: WaClient,
        private RouteParam: ActivatedRoute
    ) {
        let routeparamsSub = this.RouteParam.params.subscribe((params) => {
            this.getDoctorByID(params['id']);
        });
    }
    getDoctorName(Doctor: any) {
        if (this.translate.currentLang == 'en') {
            return Doctor?.Staff_Name_Ar;
        } else {
            return Doctor?.Staff_Name_Ar;
        }
    }
    getDescription(Doctor: any) {
        return Doctor?.Doctor_Desc;
    }
    getDoctorImg(Doctor: any) {
        return this.URLImg + Doctor?.Staff_PicUrl;
    }
    getDoctorByID(id: any) {
        this.DoctorClient.wa_getDoctorsCMS({ Staff_ID: id }).subscribe(
            (response: any) => {
                this.Doctor = response.Doctors[0];
                console.log(response);
            }
        );
    }
    ngOnInit(): void {}
}
