import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import {
    ArchwizardModule,
    NavigationMode,
    WizardComponent,
} from 'angular-archwizard';
import { TranslateService } from '@ngx-translate/core';
import { ClinicClient } from '../../../Services/clinic.service';
import { WaClient } from 'src/app/Services/Wa.service';
import swal from 'sweetalert';
import {
    AppintmentAPIClient,
    Appointment,
    CreateAppointment,
} from '../../../Services/appointment.service';
import { getPatient, sessionIsExpired } from 'src/app/Functions/Utilities';
import { Router } from '@angular/router';
import { AccountClient } from 'src/app/Services/accountService.service';
import { PaymobServiceService } from '../../../Services/paymobService.service';
import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
    selector: 'app-clinics',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './clinics.component.html',
    styleUrls: ['./clinics.component.scss'],
})
export class ClinicsComponent implements OnInit {
    @ViewChild(WizardComponent)
    public wizard: WizardComponent;
    date = new Date();
    awWizardDirection: string;
    awWizardStepSymbol: '';
    clinicOrginallist: any[] = [];
    clinicFilterlist: any[] = [];
    doctorsOrginallist: any[] = [];
    mediaList: any[] = [];
    doctorsFilterlist: any[] = [];
    serviceFilterlist: any[] = [];
    serviceOrginallist: any[] = [];
    appointmentList: any[] = [];
    DoctorAppointments: any[] = [];
    Card_Payment_Key: any;
    searchControl: any = {
        clinic: '',
        doctor: '',
        service: '',
    };
    selectedsteps: any = {
        clinic: '',
        doctor: '',
        AppointmentDate: '',
        appointment: '',
        service: '',
        payType: '',
        Date: '',
    };
    MobilePaymentForm: FormGroup;
    constructor(
        public translate: TranslateService,
        private clinicClient: ClinicClient,
        private waClient: WaClient,
        private appintmentAPIClient: AppintmentAPIClient,
        private route: Router,
        private paymobServiceService: PaymobServiceService,
        private fb: FormBuilder
    ) {
        this.getawWizardDirection();
        this.getClinics();
        this.getMedia();
    }

    isValidDate(dateString) {
        const MinDate = new Date('1752-01-01');
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString) && MinDate < new Date(dateString);
    }
    createForm() {
        this.MobilePaymentForm = this.fb.group({
            phone: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
            ],
        });
    }
    selectApp(appointment) {
        this.selectedsteps.appointment = appointment;
        $('#inserAppModel').modal('show');
    }

    searchAppointment() {
        // if (this.isValidDate(this.selectedsteps.AppointmentDate)) {
        var FromDate = new Date();
        FromDate.setDate(FromDate.getMonth() + 1);
        var FromDateString = FromDate.toISOString().substr(0, 10);

        let appSearchParam = {
            StartDate: new Date(),
            DoctorCode: this.selectedsteps.doctor.DoctorCode,
            IS_TeleMed: false,
            PatientCode: '',
            Lang: '',
        };

        this.appintmentAPIClient
            .appintmentAPI_GetPatientAppointments(appSearchParam)
            .subscribe((response: any) => {
                this.appointmentList = [];
                this.DoctorAppointments=[]
                if (response.DoctorAppointments.length > 0) {
                    this.DoctorAppointments = response.DoctorAppointments;
                    this.getAppointmentBydate(
                        response.DoctorAppointments[0].Date
                    );
                  
                }
            });
    }
    getAppointmentBydate(Date: any) {
        this.selectedsteps.Date = Date;
        this.appointmentList = this.DoctorAppointments.filter(
            (x) => x.Date == Date
        )[0].DoctorAppointments;
    }
    choosePayType(payType: any) {
        this.selectedsteps.payType = payType;
    }
    onSubmitMobilePaymentForm() {
        this.paymobServiceService
            .getAuthKeyFromSmsSevice()
            .subscribe((responseToken: any) => {
                const token = responseToken.token;
                const Patient_FirstName_Ar = getPatient()?.Patient_FirstName_Ar;
                const Patient_LastName_Ar = getPatient()?.Patient_LastName_Ar;
                const appointment = {
                    app_id: this.selectedsteps.AppointmentDate,
                    slot_id:
                        this.selectedsteps.appointment.Appo_Period.replaceAll(
                            'AM',
                            ''
                        ).replaceAll('PM', ''),
                    pat_code: getPatient()?.Patient_Code,
                    dr_code: this.selectedsteps.doctor.DoctorCode,
                    CinicDept_Code: this.selectedsteps.clinic.Clinic_Code,
                };
                this.appintmentAPIClient
                    .rescheduleOnline(appointment)
                    .subscribe((responseApp: any) => {
                        let slot =
                            this.selectedsteps.appointment.Appo_Period.replaceAll(
                                'AM',
                                ''
                            ).replaceAll('PM', '');

                        const BookingID = responseApp.BookingID;
                        const slot_id = slot.toString().split('-');
                        const getSeconds = (s) =>
                            s
                                .split(':')
                                .reduce((acc, curr) => acc * 60 + +curr, 0);

                        var seconds1 = getSeconds(slot_id[0]);
                        var seconds2 = getSeconds(slot_id[1]);
                        var res = Math.abs(seconds2 - seconds1);
                        var seconds = res % 60;
                        let Duration = seconds;
                        const service = {
                            Fk_Appo_ID: BookingID,
                            ServiceCode: this.selectedsteps.service.ServiceCode,
                            ServicePrice:
                                this.selectedsteps.service.ServiceAmount,
                            Duration: Duration ?? 30,
                            Duration_Count: 1,
                        };
                        this.appintmentAPIClient
                            .appintmentAPI_insert_AppointmentServices(service)
                            .subscribe(() => {
                                this.appintmentAPIClient
                                    .UpdateAppointmentStatus({
                                        Appo_ID: BookingID,
                                        Status: 0,
                                    })
                                    .subscribe((re) => {
                                        let order = {
                                            auth_token: token,
                                            merchant_order_id: BookingID,
                                            delivery_needed: 'false',
                                            amount_cents:
                                                this.selectedsteps.service
                                                    .ServiceAmount,
                                            currency: 'EGP',
                                            items: [
                                                {
                                                    name: this.selectedsteps
                                                        .service.ServiceCode,
                                                    amount_cents:
                                                        this.selectedsteps
                                                            .service
                                                            .ServiceAmount,
                                                    description: 'Service',
                                                    quantity: Duration ?? 30,
                                                },
                                            ],
                                        };
                                        this.paymobServiceService
                                            .Order_Regestiration(order)
                                            .subscribe(
                                                (
                                                    response_Order_Regestiration
                                                ) => {
                                                    const Order_id =
                                                        response_Order_Regestiration.id;
                                                    const billing_data = {
                                                        auth_token: token,
                                                        amount_cents:
                                                            this.selectedsteps
                                                                .service
                                                                .ServiceAmount,
                                                        expiration: 3600,
                                                        order_id: Order_id,
                                                        billing_data: {
                                                            apartment: 'NA',
                                                            email: getPatient()
                                                                .Email,
                                                            floor: 'NA',
                                                            first_name:
                                                                Patient_FirstName_Ar,
                                                            street: 'Na',
                                                            building: 'Na',
                                                            phone_number:
                                                                getPatient()
                                                                    ?.Patient_Mobile,
                                                            shipping_method:
                                                                'Na',
                                                            postal_code: 'Na',
                                                            city: 'NA',
                                                            country: 'NA',
                                                            last_name:
                                                                Patient_LastName_Ar,
                                                            state: 'NA',
                                                        },
                                                        currency: 'EGP',
                                                        integration_id: 4544254,
                                                        lock_order_when_paid:
                                                            'false',
                                                    };
                                                    this.paymobServiceService
                                                        .Card_Payment_Key(
                                                            billing_data
                                                        )
                                                        .subscribe(
                                                            (
                                                                response_Card_Payment_Key
                                                            ) => {
                                                                const card_pay =
                                                                    response_Card_Payment_Key.token;
                                                                const phone =
                                                                    this
                                                                        .MobilePaymentForm
                                                                        .value
                                                                        ?.phone;
                                                                const paymentPhoneobj =
                                                                    {
                                                                        source: {
                                                                            identifier:
                                                                                phone,
                                                                            subtype:
                                                                                'WALLET',
                                                                        },
                                                                        payment_token:
                                                                            card_pay,
                                                                    };
                                                                this.paymobServiceService
                                                                    .pay_Mobile(
                                                                        paymentPhoneobj
                                                                    )
                                                                    .subscribe(
                                                                        (
                                                                            response
                                                                        ) => {
                                                                            if (
                                                                                true
                                                                            ) {
                                                                                $(
                                                                                    '#inserAppModel'
                                                                                ).modal(
                                                                                    'hide'
                                                                                );
                                                                                $(
                                                                                    '#MobilePaymentModel'
                                                                                ).modal(
                                                                                    'hide'
                                                                                );
                                                                                this.searchAppointment();
                                                                                window.open(
                                                                                    response.redirect_url,
                                                                                    '_blank' // <- This is what makes it open in a new window.
                                                                                );
                                                                            } else {
                                                                                this.appintmentAPIClient
                                                                                    .appintmentAPI_CancelAppointment(
                                                                                        {
                                                                                            Appo_ID:
                                                                                                BookingID,
                                                                                        }
                                                                                    )
                                                                                    .subscribe(
                                                                                        (
                                                                                            response
                                                                                        ) => {
                                                                                            $(
                                                                                                '#inserAppModel'
                                                                                            ).modal(
                                                                                                'hide'
                                                                                            );
                                                                                            $(
                                                                                                '#MobilePaymentModel'
                                                                                            ).modal(
                                                                                                'hide'
                                                                                            );
                                                                                            swal(
                                                                                                {
                                                                                                    title: this.translate.instant(
                                                                                                        'errorInPayment'
                                                                                                    ),
                                                                                                    text: this.translate.instant(
                                                                                                        'checkSupport'
                                                                                                    ),
                                                                                                    icon: 'error',
                                                                                                    timer: 2000,
                                                                                                    buttons:
                                                                                                        {},
                                                                                                }
                                                                                            );

                                                                                            this.searchAppointment();
                                                                                        }
                                                                                    );
                                                                            }
                                                                        }
                                                                    );

                                                                this.searchAppointment();
                                                            }
                                                        );
                                                }
                                            );
                                    });
                            });
                    });
            });
    }
    insertAppointment() {
        if (!sessionIsExpired()) {
            if (this.selectedsteps.payType == '') {
                return;
            }
            if (this.selectedsteps.payType == 1) {
                this.paymobServiceService
                    .getAuthKeyFromSmsSevice()
                    .subscribe((responseToken: any) => {
                        const token = responseToken.token;
                        const Patient_FirstName_Ar =
                            getPatient()?.Patient_FirstName_Ar;
                        const Patient_LastName_Ar =
                            getPatient()?.Patient_LastName_Ar;
                        const appointment = {
                            app_id: this.selectedsteps.AppointmentDate,
                            slot_id:
                                this.selectedsteps.appointment.Appo_Period.replaceAll(
                                    'AM',
                                    ''
                                ).replaceAll('PM', ''),
                            pat_code: getPatient()?.Patient_Code,
                            dr_code: this.selectedsteps.doctor.DoctorCode,
                            CinicDept_Code:
                                this.selectedsteps.clinic.Clinic_Code,
                        };
                        this.appintmentAPIClient
                            .rescheduleOnline(appointment)
                            .subscribe((responseApp: any) => {
                                let slot =
                                    this.selectedsteps.appointment.Appo_Period.replaceAll(
                                        'AM',
                                        ''
                                    ).replaceAll('PM', '');
                                const BookingID = responseApp.BookingID;
                                const slot_id = slot.toString().split('-');
                                const getSeconds = (s) =>
                                    s
                                        .split(':')
                                        .reduce(
                                            (acc, curr) => acc * 60 + +curr,
                                            0
                                        );

                                var seconds1 = getSeconds(slot_id[0]);
                                var seconds2 = getSeconds(slot_id[1]);
                                var res = Math.abs(seconds2 - seconds1);
                                var seconds = res % 60;
                                let Duration = seconds;
                                const service = {
                                    Fk_Appo_ID: BookingID,
                                    ServiceCode:
                                        this.selectedsteps.service.ServiceCode,
                                    ServicePrice:
                                        this.selectedsteps.service
                                            .ServiceAmount,
                                    Duration: Duration ?? 30,
                                    Duration_Count: 1,
                                };
                                this.appintmentAPIClient
                                    .appintmentAPI_insert_AppointmentServices(
                                        service
                                    )
                                    .subscribe(() => {
                                        this.appintmentAPIClient
                                            .UpdateAppointmentStatus({
                                                Appo_ID: BookingID,
                                                Status: 0,
                                            })
                                            .subscribe((re) => {
                                                let order = {
                                                    auth_token: token,
                                                    merchant_order_id:
                                                        BookingID,
                                                    delivery_needed: 'false',
                                                    amount_cents:
                                                        this.selectedsteps
                                                            .service
                                                            .ServiceAmount,
                                                    currency: 'EGP',
                                                    items: [
                                                        {
                                                            name: this
                                                                .selectedsteps
                                                                .service
                                                                .ServiceCode,
                                                            amount_cents:
                                                                this
                                                                    .selectedsteps
                                                                    .service
                                                                    .ServiceAmount,
                                                            description:
                                                                'Service',
                                                            quantity:
                                                                Duration ?? 30,
                                                        },
                                                    ],
                                                };
                                                this.paymobServiceService
                                                    .Order_Regestiration(order)
                                                    .subscribe(
                                                        (
                                                            response_Order_Regestiration
                                                        ) => {
                                                            const Order_id =
                                                                response_Order_Regestiration.id;
                                                            const billing_data =
                                                                {
                                                                    auth_token:
                                                                        token,
                                                                    amount_cents:
                                                                        this
                                                                            .selectedsteps
                                                                            .service
                                                                            .ServiceAmount,
                                                                    expiration: 3600,
                                                                    order_id:
                                                                        Order_id,
                                                                    billing_data:
                                                                        {
                                                                            apartment:
                                                                                'NA',
                                                                            email: getPatient()
                                                                                .Email,
                                                                            floor: 'NA',
                                                                            first_name:
                                                                                Patient_FirstName_Ar,
                                                                            street: 'Na',
                                                                            building:
                                                                                'Na',
                                                                            phone_number:
                                                                                getPatient()
                                                                                    ?.Patient_Mobile,
                                                                            shipping_method:
                                                                                'Na',
                                                                            postal_code:
                                                                                'Na',
                                                                            city: 'NA',
                                                                            country:
                                                                                'NA',
                                                                            last_name:
                                                                                Patient_LastName_Ar,
                                                                            state: 'NA',
                                                                        },
                                                                    currency:
                                                                        'EGP',
                                                                    integration_id: 22660,
                                                                    lock_order_when_paid:
                                                                        'false',
                                                                };
                                                            this.paymobServiceService
                                                                .Card_Payment_Key(
                                                                    billing_data
                                                                )
                                                                .subscribe(
                                                                    (
                                                                        response_Card_Payment_Key
                                                                    ) => {
                                                                        const card_pay =
                                                                            response_Card_Payment_Key.token;
                                                                        this.searchAppointment();
                                                                        window.open(
                                                                            `https://accept.paymob.com/api/acceptance/iframes/16598?payment_token=${card_pay}`,
                                                                            '_blank' // <- This is what makes it open in a new window.
                                                                        );
                                                                    }
                                                                );
                                                        }
                                                    );
                                            });
                                    });
                            });
                    });
            } else if (this.selectedsteps.payType == 3) {
                $('#MobilePaymentModel').modal('show');
            }
        } else {
            $('#loginFirstModel').modal('show');
            $('#inserAppModel').modal('hide');
        }
    }
    goToSignin() {
        $('#inserAppModel').modal('hide');
        $('#loginFirstModel').modal('hide');
        this.route.navigate(['sign-in']);
    }
    previousDate() {
        if (this.selectedsteps.AppointmentDate != '') {
            var result = new Date(this.selectedsteps.AppointmentDate);
            result.setDate(result.getDate() - 1);
            this.selectedsteps.AppointmentDate = result
                .toISOString()
                .substr(0, 10);
            this.searchAppointment();
        }
    }
    NextDate() {
        if (this.selectedsteps.AppointmentDate != '') {
            var result = new Date(this.selectedsteps.AppointmentDate);
            result.setDate(result.getDate() + 1);
            this.selectedsteps.AppointmentDate = result
                .toISOString()
                .substr(0, 10);
            this.searchAppointment();
        }
    }

    sessionIsExpired() {
        return sessionIsExpired();
    }
    search() {
        this.clinicFilterlist = this.clinicOrginallist.filter((clinic) => {
            if (this.translate.currentLang == 'en')
                return clinic.CodeDescriptionEn.toLowerCase().includes(
                    this.searchControl.clinic?.trim().toLowerCase()
                );
            else
                return clinic.CodeDescriptionAr.toLowerCase().includes(
                    this.searchControl.clinic?.trim().toLowerCase()
                );
        });
        this.serviceFilterlist = this.serviceOrginallist.filter((service) => {
            if (this.translate.currentLang == 'en')
                return service.ServiceNameEn.toLowerCase().includes(
                    this.searchControl.service?.trim().toLowerCase()
                );
            else
                return service.ServiceNameAr.toLowerCase().includes(
                    this.searchControl.service?.trim().toLowerCase()
                );
        });

        this.doctorsFilterlist = this.doctorsOrginallist.filter((clinic) => {
            if (this.translate.currentLang == 'en')
                return clinic.DoctorNameEn.toLowerCase().includes(
                    this.searchControl.doctor?.trim().toLowerCase()
                );
            else
                return clinic.DoctorNameAr.toLowerCase().includes(
                    this.searchControl.doctor?.trim().toLowerCase()
                );
        });
    }
    getawWizardDirection() {
        if (document.dir == 'rtl') {
            this.awWizardDirection = 'rtl';
        } else {
            this.awWizardDirection = 'ltr';
        }
    }

    getClinics() {
        this.clinicClient.clinic_GetClinics({}).subscribe((response: any) => {
            var result = response.Clinics.reduce((unique, o) => {
                if (!unique.some((obj) => obj.Clinic_Code === o.Clinic_Code)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            this.clinicOrginallist = result;
            this.clinicFilterlist = result;
        });
    }

    getClinicName(clinic: any) {
        return this.translate.currentLang == 'en'
            ? clinic?.ClinicDept_Name_En ?? ''
            : clinic?.ClinicDept_Name_Ar ?? '';
    }

    getClinicDeptName(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.ClinicDept_Name_En ?? ''
            : doctor?.ClinicDept_Name_Ar ?? '';
    }
    getSpecialization(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.SpecializationEn ?? ''
            : doctor?.SpecializationAr ?? '';
    }

    selectClinic(clinic: any) {
        this.selectedsteps.clinic = clinic;
    }
    selectDoctor(doctor: any) {
        this.selectedsteps.doctor = doctor;
    }
    selectService(service: any) {
        this.selectedsteps.service = service;
    }

    getDoctorName(doctor: any) {
        return this.translate.currentLang == 'en'
            ? doctor?.DoctorNameEn ?? ''
            : doctor?.DoctorNameAr ?? '';
    }
    getDoctorIMG(doctor) {
        let pic = this.mediaList.filter(
            (x) => x.Media_FileName == doctor.DoctorCode
        );
        return pic.length > 0
            ? 'http://localhost:50439/Uploads/Media/' + pic[0]?.Media_Path ?? ''
            : '../../../../assets/img/user.png';
    }
    getServiceName(service: any) {
        return this.translate.currentLang == 'en'
            ? service?.ServiceNameEn ?? ''
            : service?.ServiceNameAr ?? '';
    }

    goToAppointementStep() {
        if (this.selectedsteps.doctor != '') {
            this.wizard.goToNextStep();
            this.searchAppointment();
        } else {
            swal({
                title: this.translate.instant('gotoAooStepFail'),
                text: this.translate.instant('gotoAppStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    getMedia() {
        this.waClient
            .wa_getMedia({
                Media_FileName: null,
            })
            .subscribe((response: any) => {
                this.mediaList = response.Media;
            });
    }
    goToDoctorStep() {
        if (this.selectedsteps.service != '') {
            this.getLocation().then((resp) => {
                let doctorParam = {
                    DoctorSpeciality: this.selectedsteps.clinic.CodeUnique,
                    ClinicDept_Code: this.selectedsteps.clinic.Clinic_Code,
                    Doctor_Code: null,
                    // Long: resp.long,
                    // Lat: resp.lat,
                    // DistanceKilos: '1000',
                    Long: 0,
                    Lat: 0,
                    DistanceKilos: null,
                    CurrentPage: null,
                    PageSize: null,
                    UseLocation: null,
                };
                this.waClient
                    .wa_GetDoctors(doctorParam)
                    .subscribe((response: any) => {
                        this.doctorsOrginallist = response.Doctors;
                        this.doctorsFilterlist = response.Doctors;
                        this.wizard.goToNextStep();
                    });
            });
        } else {
            swal({
                title: this.translate.instant('gotodoctorStepFail'),
                text: this.translate.instant('gotodoctorStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    goToStep() {
        if (this.selectedsteps.clinic != '') {
            const serviceParam = {
                ClinicDeptCode: this.selectedsteps.clinic.Clinic_Code,
            };
            this.waClient
                .wa_GetServiceByClinicCode(serviceParam)
                .subscribe((response: any) => {
                    this.serviceFilterlist = response.Servicelist;
                    this.serviceOrginallist = response.Servicelist;
                    this.wizard.goToNextStep();
                });
        } else {
            swal({
                title: this.translate.instant('gotoserviceStepFail'),
                text: this.translate.instant('gotoserviceStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    goToServiceStep() {
        if (this.selectedsteps.clinic != '') {
            const serviceParam = {
                ClinicDeptCode: this.selectedsteps.clinic.Clinic_Code,
            };
            this.waClient
                .wa_GetServiceByClinicCode(serviceParam)
                .subscribe((response: any) => {
                    this.serviceFilterlist = response.Servicelist;
                    this.serviceOrginallist = response.Servicelist;
                    this.wizard.goToNextStep();
                });
        } else {
            swal({
                title: this.translate.instant('gotoserviceStepFail'),
                text: this.translate.instant('gotoserviceStepFailReason'),
                icon: 'error',
                timer: 4000,
                buttons: {},
            });
        }
    }
    getLocation(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((resp) => {
                resolve({
                    long: resp.coords.longitude,
                    lat: resp.coords.latitude,
                });
                reject(() => {
                    swal({
                        title: this.translate.instant('gotodoctorStepFail'),
                        text: this.translate.instant('openLocation'),
                        icon: 'error',
                        timer: 4000,
                        buttons: {},
                    });
                });
            });
        });
    }
    ngOnInit(): void {
        this.createForm();
    }
}
